import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import { InvoiceType } from "./orgSubscription-types";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { CorporationOperation } from "../../../serviceoperation/CorporationOperation";
import { CompanyContext } from "../../../contexts/company-context";
import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CheckCircleFilled, DownloadOutlined } from "@ant-design/icons";
import { pricingTable } from "../../../constants";

//AddScheduleForm
const OrgSubscription = () => {
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const [invoices, setInvoices] = useState<InvoiceType[]>([]);
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(true);
  const essential = pricingTable.essential;
  const bsic = pricingTable.basic;

  //const essential = "prod_R1gE5ctoTZgzIQ";
  //const bsic = "prod_R1gEDKj6tdG004";

  const { Title, Paragraph, Text } = Typography;
  const [state, setState] = useState({
    number: "0***********1111",
    expiry: "12/23",
    cvc: "",
    name: "Brock Landers",
    focus: "",
  });

  // prod_R1F0Nt9CyKyV4E -- Basic
  // prod_R1F3uZGw0s5jQm -- Essential

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  useEffect(() => {
    const compOps = new CorporationOperation();
    compOps
      .getInvoiceAsync(selectedCompany?.corporationId as number)
      .then((data) => {
        if (data.data) {
          if (data.data.data) {
            setInvoices(
              data.data.data.map((x) => ({
                key: x.id,
                date: new Date(x.created * 1000).toLocaleDateString(),
                details: x?.lines?.data[0]?.description ?? null,
                amount: formatCurrency(x.total / 100),
                url: x.invoice_pdf,
              }))
            );
          }
        }
        setInvoiceLoading(false);
      })
      .catch(() => {
        setInvoiceLoading(false);
      });
  }, [selectedCompany, userInfo]);

  const columns: ColumnsType<InvoiceType> = [
    {
      title: "Date",
      dataIndex: "date",
      width: "30%",
      responsive: ["lg"],
    },
    {
      title: "Details",
      dataIndex: "details",
      width: "30%",
      responsive: ["lg"],
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "20%",
    },
    {
      title: "Download",
      dataIndex: "url",
      width: "15%",
      render: (_, record) => {
        return (
          <Space size="middle">
            {record.url ? (
              <DownloadOutlined
                onClick={() => {
                  // Open the URL in a new tab to initiate the download
                  window.open(record.url);
                }}
              />
            ) : null}
          </Space>
        );
      },
    },
  ];

  return (
    <PageContainer
      style={{ marginLeft: "-16px", marginRight: "-16px" }}
      breadcrumbRender={false}
      title="Plan"
    >
      <Row gutter={16}>
        {/* We never want to show anyone the Basic plan if they are on Essentials */}
        {selectedCompany?.planId == bsic ? (
          <Col xs={24} sm={12} md={12}>
            <Card
              bordered={true}
              style={{
                borderColor: "#4C97F0",
                backgroundColor: "#F7FBFF",
              }}
            >
              {selectedCompany?.planId == bsic ? (
                <div
                  style={{
                    position: "absolute",
                    top: -10, // Move the image slightly outside the top border
                    right: -10, // Move the image slightly outside the right border
                    width: 20, // Adjust image size
                    height: 20, // Adjust image size
                  }}
                >
                  <CheckCircleFilled
                    style={{ color: "#4C97F0", fontSize: "16px" }}
                  />
                </div>
              ) : null}
              <Row gutter={16}>
                {/* Left side for Title and Description */}
                <Col xs={24} sm={16}>
                  <div>
                    <Title level={5} style={{ color: "black" }}>
                      Basic
                    </Title>
                    <Paragraph style={{ color: "black" }}>
                      Basic plan.
                    </Paragraph>
                  </div>
                  {/* Cancel Plan Button */}
                  <Button
                    style={{
                      borderColor: "#D2E7FC",
                      backgroundColor: "#F7FBFF",
                      color: "#4C97F0",
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </Col>

                {/* Right side for Pricing */}
                <Col
                  xs={24}
                  sm={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Title level={5} style={{ marginTop: "0", color: "black" }}>
                    $0 / month
                  </Title>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}
        {/* Second Card */}

        <Col xs={24} sm={12} md={12}>
          <Card bordered={true} style={{ backgroundColor: "#6C77E8" }}>
            {selectedCompany?.planId == essential ? (
              <div
                style={{
                  position: "absolute",
                  top: -10, // Move the image slightly outside the top border
                  right: -10, // Move the image slightly outside the right border
                  width: 20, // Adjust image size
                  height: 20, // Adjust image size
                }}
              >
                <CheckCircleFilled
                  style={{ color: "#6C77E8", fontSize: "16px" }}
                />
              </div>
            ) : null}
            <Row gutter={16}>
              {/* Left side for Title and Description */}
              <Col xs={24} sm={18}>
                <div>
                  <Title level={5} style={{ color: "white" }}>
                    Essential
                  </Title>
                  <Paragraph style={{ color: "white" }}>
                    Essential Plan.
                  </Paragraph>
                </div>
                {/* Cancel Plan Button */}
                {selectedCompany?.planId == bsic ? (
                  <Button
                    style={{
                      color: "#6C77E8",
                    }}
                  >
                    Upgrade
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: "#6C77E8",
                    }}
                  >
                    Cancel Subscription
                  </Button>
                )}
              </Col>

              {/* Right side for Pricing */}
              <Col
                xs={24}
                sm={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <h3 style={{ marginTop: "0", color: "white" }}>
                  $5 location / month
                </h3>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <Title level={5}>Auto renew</Title>
      <Paragraph>
        Your subscription is set to auto-renew. This means that your plan will
        automatically renew for the next billing cycle, and the payment will be
        processed using the payment method on file. If you'd like to make any
        changes to your subscription or update your payment information, please
        visit our billing management page{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={pricingTable.billingUrl}
        >
          here{" "}
        </a>
        .
      </Paragraph>
      {/*<br />
      <Title level={5}>Payment Method</Title>
      <Space size={16}>
        <Cards
          number={state.number}
          expiry={state.expiry}
          cvc={state.cvc}
          name={state.name}
          issuer={"VISA"}
        />
      </Space>*/}
      <br />
      <br />
      <Title level={5}>Billing History</Title>
      <Table
        style={{ whiteSpace: "pre", cursor: "pointer" }}
        columns={columns}
        pagination={false}
        dataSource={invoices}
        loading={invoiceLoading}
      />
    </PageContainer>
  );
};

export default OrgSubscription;
