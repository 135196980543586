import { useContext } from "react";
import { constants } from "../constants";
import { AuthenticationContext } from "../contexts/authentication-context";

import {
  InsertActivityStatus,
  InsertSchedule,
  PatchScheduleActivity,
  PostScheduleBulkInsert,
  RequestEmployeeSchedule,
  ResponseScheduleActivity,
  ScheduleDetail,
  ScheduleDetails,
  ScheduleList,
  ScheduleReport,
  ScheduleReportQuery,
  Status,
} from "./entity/ScheduleOperationEntity";
import { StringFormat } from "../Util/stringutil";
import { myFetch } from "./myFetch";

// Interface for the Corporation API calls...
export default interface IScheduleOperation {
  getScheduleDetailsAsync(
    corpId: number,
    scheduleId: number
  ): Promise<ScheduleDetail>;
  getScheduleAsync(
    corpId: number,
    reqEmployeeSchedule: RequestEmployeeSchedule
  ): Promise<ScheduleList>;
  getScheduleByIdAsync(
    corpId: number,
    scheduleId: number
  ): Promise<ScheduleDetails>;
  getScheduleActivityAsync(
    corpId: number,
    scheduleId: number
  ): Promise<ResponseScheduleActivity>;
  postScheduleActivityAsync(
    corpId: number,
    scheduleId: number,
    employeeId: string,
    activityType: string,
    latitude: number,
    longitude: number,
    altitude: number
  ): Promise<InsertActivityStatus>;

  patchScheduleActivityAsync(
    corpId: number,
    scheduleId: number,
    patchSchedule: PatchScheduleActivity[]
  ): Promise<Status>;

  postScheduleAsync(
    corpId: number,
    employeeId: string,
    insertSchedule: InsertSchedule
  ): Promise<Status>;

  deleteScheduleAsync(corpId: number, scheduleId: number): Promise<any>;

  getScheduleReportAsync(
    corpId: number,
    scheduleQuery: ScheduleReportQuery
  ): Promise<ScheduleReport>;

  postBulkScheduleAsync(
    corpId: number,
    bulkSchedule: PostScheduleBulkInsert
  ): Promise<Status>;
}

export class ScheduleOperation implements IScheduleOperation {
  async postBulkScheduleAsync(
    corpId: number,
    bulkSchedule: PostScheduleBulkInsert
  ): Promise<Status> {
    const response = await myFetch<Status>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_BULK_SCHEDULE_ENDPOINT,
        corpId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(bulkSchedule),
      }
    );

    const data = await response.json();
    return data;
  }

  async patchScheduleActivityAsync(
    corpId: number,
    scheduleId: number,
    patchSchedule: PatchScheduleActivity[]
  ): Promise<Status> {
    const response = await myFetch<Status>(
      `${constants.BASE_URL}${StringFormat(
        constants.PATCH_SCHEDULE_ACTIVITY_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "PATCH",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(patchSchedule),
      }
    );

    const data = await response.json();
    return data;
  }

  async getScheduleReportAsync(
    corpId: number,
    scheduleQuery: ScheduleReportQuery
  ): Promise<ScheduleReport> {
    const querystring = Object.entries(scheduleQuery)
      .map(([key, value]) => key + "=" + value)
      .join("&");
    const response = await myFetch<ScheduleReport>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_REPORT,
        corpId
      )}?${querystring}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer  Dummy`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async deleteScheduleAsync(corpId: number, scheduleId: number) {
    const response = await fetch(
      `${constants.BASE_URL}${StringFormat(
        constants.DELETE_SCHEDULE_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
      }
    );
    return response;
  }

  async postScheduleAsync(
    corpId: number,
    employeeId: string,
    insertSchedule: InsertSchedule
  ): Promise<Status> {
    const response = await myFetch<Status>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_SCHEDULE_ENDPOINT,
        corpId,
        employeeId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify({
          title: insertSchedule.title,
          note: insertSchedule.note,
          workDay: insertSchedule.workDay,
          startWorkHour: insertSchedule.startWorkHour,
          startWorkMinute: insertSchedule.startWorkMinute,
          endWorkHour: insertSchedule.endWorkHour,
          endWorkMinute: insertSchedule.endWorkMinute,
          timeZoneId: insertSchedule.timeZoneId,
          branchId: insertSchedule.branchId,
          notification: insertSchedule.notification
            ? {
                notifyEmployee: insertSchedule.notification?.notifyEmployee,
                title: insertSchedule.notification?.title,
                body: insertSchedule.notification?.body,
              }
            : null,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
  async getScheduleDetailsAsync(
    corpId: number,
    scheduleId: number
  ): Promise<ScheduleDetail> {
    const response = await myFetch<ScheduleDetail>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_BY_ID_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
          //Authorization: `Bearer ${useContext(AuthenticationContext)?.accessToken}`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
  async getScheduleAsync(
    corpId: number,
    reqEmployeeSchedule: RequestEmployeeSchedule
  ): Promise<ScheduleList> {
    const querystring = Object.entries(reqEmployeeSchedule)
      .map(([key, value]) => key + "=" + value)
      .join("&");
    const response = await myFetch<ScheduleList>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_ENDPOINT,
        corpId
      )}?${querystring}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer  Dummy`,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async getScheduleByIdAsync(corpId: number, scheduleId: number) {
    const response = await myFetch<ScheduleDetails>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_BY_ID_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async getScheduleActivityAsync(
    corpId: number,
    scheduleId: number
  ): Promise<ResponseScheduleActivity> {
    const response = await myFetch<ResponseScheduleActivity>(
      `${constants.BASE_URL}${StringFormat(
        constants.GET_SCHEDULE_ACTIVITY_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: ``,
        }),
      }
    );

    const data = await response.json();
    return data;
  }

  async postScheduleActivityAsync(
    corpId: number,
    scheduleId: number,
    employeeId: string,
    activityType: string,
    latitude: number,
    longitude: number,
    altitude: number
  ): Promise<InsertActivityStatus> {
    const response = await myFetch<InsertActivityStatus>(
      `${constants.BASE_URL}${StringFormat(
        constants.POST_SCHEDULE_ACTIVITY_ENDPOINT,
        corpId,
        scheduleId
      )}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: ``,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify({
          employeeId: employeeId,
          activityType: activityType,
          latitude: latitude,
          longitude: longitude,
          altitude: altitude,
        }),
      }
    );

    const data = await response.json();
    return data;
  }
}
