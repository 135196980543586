export const constants = {
    GET_CORP_ENDPOINT: "/v1/Corporation/",
    GET_CORP_BY_ID_ENDPOINT: "/v1/Corporation/{0}",
    POST_CORP: "/v1/Corporation",
    PATCH_CORP: "/v1/Corporation/{0}",
    GET_CORPINVOICE: "/v1/Corporation/{0}/invoices",
    GET_CORPSUBSCRIPTION: "/v1/Corporation/{0}/subscription",
    GET_SCHEDULE_BY_ID_ENDPOINT: "/v1/corporation/{0}/Schedule/{1}",
    GET_SCHEDULE_ENDPOINT: "/v1/corporation/{0}/Schedule",
    PATCH_SCHEDULE_ACTIVITY_ENDPOINT: "/v1/corporation/{0}/Schedule/{1}/activity",
    GET_SCHEDULE_ACTIVITY_ENDPOINT: "/v1/corporation/{0}/Schedule/{1}/activity",
    POST_SCHEDULE_ACTIVITY_ENDPOINT: "/v1/corporation/{0}/Schedule/{1}/activity",
    POST_SCHEDULE_ENDPOINT:"/v1/corporation/{0}/Employee/{1}/Schedule",
    POST_BULK_SCHEDULE_ENDPOINT:"/v1/corporation/{0}/Schedule",
    GET_TIMEOFF_REASON: "/v1/corporation/{0}/TimeOff/reasons",
    POST_TIMEOFF_REASON: "/v1/corporation/{0}/TimeOff/reasons",
    GET_TIMEOFF_ENDPOINT: "/v1/corporation/{0}/Employee/TimeOff",
    GET_TIMEOFF_STAT_ENDPOINT: "/v1/corporation/{0}/Employee/TimeOff/Statistics",
    POST_SCHEDULE_TIMEOFF_ENDPOINT: "/v1/corporation/{0}/Schedule/{1}/TimeOff",
    POST_TIMEOFF_ENDPOINT: "/v1/corporation/{0}/Employee/{1}/TimeOff",
    POST_SCHEDULE_TIMEOFF_STATUS_ENDPOINT: "/v1/corporation/{0}/TimeOff/{1}/{2}",
    DELETE_SCHEDULE_ENDPOINT:"/v1/corporation/{0}/Schedule/{1}",
    GET_SCHEDULE_REPORT:"/v1/corporation/{0}/Schedule/Report",
    GET_SCHEDULE_REPLACEMENT:"/v1/corporation/{0}/Schedule/{1}/replacement/match",
    GET_SCHEDULE_REPLACEMENT_REQUEST: "/v1/corporation/{0}/Schedule/replacement/request",
    GET_SCHEDULE_REPLACEMENT_NOTI: "/v1/corporation/{0}/Schedule/replacement",
    POST_SCHEDULE_REPLACEMENT: "/v1/corporation/{0}/Schedule/{1}/replacement/{2}",
    POST_SCHEDULE_REPLACEMENT_STATUS: "/v1/corporation/{0}/Schedule/replacement/{1}?status={2}",
    GET_BRANCH: "/v1/corporation/{0}/Branch/{1}",
    GET_ALL_BRANCH: "/v1/corporation/{0}/Branch",
    GET_BRANCH_DEPARTMENT: "/v1/corporation/{0}/Branch/{1}/Department",
    GET_EMPLOYEE: "/v1/corporation/{0}/Employee",
    GET_EMPLOYEE_BY_ID: "/v1/corporation/{0}/Employee/{1}",
    DELETE_EMPLOYEE:"/v1/corporation/{0}/Employee/{1}",
    GET_EMPLOYEE_AVAILABILITY:"/v1/corporation/{0}/Employee/{1}/availability",
    PATCH_EMPLOYEE_AVAILABILITY:"/v1/corporation/{0}/Employee/availability",
    GET_TIMEZONE_ENDPOINT: "/v1/TimeZone",
    INVITE_EMPLOYEE_ENDPOINT: "/InviteUserFunction",
    REINVITE_EMPLOYEE_ENDPOINT: "/Company/{0}/Employee/{1}/ReInvite",
    GET_METADATA: "/v1/Metadata?preferenceType={0}",
    BASE_URL: `${process.env.REACT_APP_API_BASE}`,
    INVITE_BASE_URL: `${process.env.REACT_APP_INVITE_API_BASE}`
}

export const timeOffStatus = {
    REQUESTED :"058e351e-b89c-4062-9495-aa92f8c8976e",
    DENY :"0c04fceb-7b09-403c-931c-7f724a70c9f2",
    APPROVE: "609fe99c-6df0-44db-974b-af1f0adfdb69",
    CANCEL: "6649de9c-48fd-427f-b243-a2389f34e79b"
}

export const pricingTable = {
    
    /*Id: "prctbl_1Q9ctARxEOQjyefAIDQdQjoF",
    publishableKey: "pk_test_51Q9cnpRxEOQjyefAlYa0uvxEhV2Xoye4DkMRWAnT3yKKcuNSpqO74EnKcmP2oZO8ssjxNnZKEk66oTI8Xow1hBfn00HJsdYhEk",*/

    // Production \\ 
    Id: "prctbl_1Q9CfnRwHPPLQwm5r3U2wNDB",
    publishableKey: "pk_live_51Q8uPXRwHPPLQwm5C1ppHgiv05zzBSwNrqMRFnDP3DvD3yEYAem5276JBGsO997CtH9isIgh3gSROwRq4L6Io6Mk00WcYZnXGM",
    billingUrl: "https://billing.stripe.com/p/login/aEU8yw1PZ6bd6VG000",
    essential: "prod_R1F3uZGw0s5jQm",
    basic: "prod_R1F0Nt9CyKyV4E",
}